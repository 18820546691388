<template>
  <!-- TOP Nav Bar -->
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom">
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <div class="iq-navbar-logo d-flex justify-content-between">
          <a href="#">
            <span @click="goHome">
              <img src="/img/intim-club-logo-web.svg" alt="" class="header-footer-visible-full">
              <img src="/img/intim-club-logo-web-srdce.svg" alt=""  class="header-footer-visible-short  logo-header-mobile">
            </span>
          </a>
          <div class="iq-menu-bt align-self-center">
            <div class="wrapper-menu" @click="miniSidebar">
              <div class="main-circle"><i class="ri-menu-line"></i></div>
            </div>
          </div>
        </div>
        <!--
        <div class="iq-search-bar">
            <form action="#" class="searchbox">
              <input type="text" class="text search-input" placeholder="Vyhledej dívku">
              <a class="search-link" href="#"><i class="ri-search-line"></i></a>
            </form>
        </div>
        
        <a @click="$router.push({path: '/chat'})">Chat</a>
-->
        <a v-if="customerData" @click="$router.push({path: '/editcustomer'})" class="iq-waves-effect user-profile d-flex align-items-center">
          <i v-if="premium" class="las la-crown"></i><i
            v-else class="ri-user-fill"></i>
          <div class="caption">
            <h6 class="mb-0 line-height">{{ name }} <span class="oddelovac"> | </span> <span id="credits">{{
                credits
              }}<i class="las la-piggy-bank"></i></span>
            </h6>
          </div>
        </a>
        <div v-else >
          <a class="header-text" @click="$router.push({path: '/loginpage'})">{{ $t("navbar.login") }}</a>
          <span class="oddelovac"> | </span>
          <a class="header-text" @click="$router.push({path: '/registration-user'})">{{ $t("navbar.register") }}</a>
        </div>
        <div>
          <span class="language_switcher" id="language_CS" @click="changeLanguage('cs')">CZ</span>
          <span class="oddelovac">|</span>
          <span class="language_switcher" id="language_EN" @click="changeLanguage('en')">EN</span>
        </div>
        <a v-if="clientData" @click="$router.push({path: '/my-profile'})"
           class="iq-waves-effect user-profile d-flex align-items-center">
          <i v-if="clientData.premium" class="las la-crown"></i><i
            v-else class="ri-user-fill"></i>

          <div class="caption">

            <h6 class="mb-0 line-height">{{ name }}</h6>

          </div>
        </a>
      </nav>
    </div>
  </div>
  <!-- TOP Nav Bar END -->
</template>
<script>
import {mapGetters} from 'vuex'
import Lottie from '../../../components/socialvue/lottie/Lottie'

const $ = require('jquery')

export default {
  name: 'NavBarStyle1',
  props: {
    homeURL: {type: Object, default: () => ({name: 'layout1.dashboard'})},
    title: {type: String, default: 'Dashboard'},
    logo: {type: String, default: require('../../../assets/images/logo.png')},
    horizontal: {type: Boolean, default: false},
    items: {type: Array}
  },
  components: {
    Lottie ,
  },
  computed: {
    ...mapGetters({
      bookmark: 'Setting/bookmarkState'
    }),
    customerData() {
      return this.$store.getters["Setting/customerData"];
    },
    clientData() {
      return this.$store.getters["Setting/clientData"];
    },
    isLogged() {
      return this.$store.getters["Setting/authUserState"];
    },
    premium() {
      if (this.clientData) {
        if (this.clientData.premium_expires_date) {
          return true
        }
      } else if (this.customerData) {
        if (this.customerData.premium_expires_date) {
          return true
        }
      } else {
        return false
      }
    },
    name() {
      if (this.customerData) {
        if (this.customerData.first_name !== undefined) {
          return this.customerData.first_name
        } else {
          return this.customerData.email
        }
      }
      if (this.clientData) {
        if (this.clientData.first_name !== undefined) {
          return this.clientData.first_name
        } else {
          return this.clientData.email
        }
      }
      return ""
    },
    credits() {
      if (this.customerData) {
        return this.customerData.credits
      } else return ""
    }
  },
  data() {
    return {
      sidebar: null,
      globalSearch: '',
      showSearch: false,
      showMenu: false,
    }
  },
  methods: {
    goHome() {
      if (window.innerWidth > 1498) {
        $('body').addClass("sidebar-main")
      } else {
        $('body').removeClass("sidebar-main")
      }
      this.$router.push({path: '/'})
    },
    miniSidebar() {
      this.$emit('toggle')
    },
    openSearch() {
      this.showSearch = true
      this.showMenu = 'iq-show'
      this.globalSearch = ''
    },
    closeSearch(event) {
      let classList = event.target.classList
      if (!classList.contains('searchbox') && !classList.contains('search-input')) {
        this.removeClass()
      }
    },
    removeClass() {
      this.showSearch = false
      this.showMenu = ''
      this.globalSearch = ''
    },
    changeLanguage(locale) {
      this.$i18n.locale = locale;
    },
  }
}
</script>
<style scoped>
#credits {
  color: gold;

  font-size: 17px;
}

#credits i {
  color: gold;

  font-size: 17px;
}

a {
  cursor: pointer !important;
}

.input {
  width: 50%;
}

[dir=ltr][mode=dark] .iq-top-navbar {
  display: list-item;
  background: var(--iq-dark-sidebar);
  line-height: 75px;
}

[dir=ltr][mode=dark] .iq-search-bar .search-input{
  background: var(--iq-dark-bg);
}

.ri-search-line{
  color: var(--iq-dark-box)
}

.oddelovac {
  color:var(--iq-dark-box);
  margin-left: 5px;
  margin-right: 5px;
}

.header-text {
  text-decoration: underline;
  font-size: 12px;
}

.language_switcher {
  font-size: 12px;
  cursor: pointer;
}

.language_switcher:hover {
  text-decoration: underline;
}

</style>
