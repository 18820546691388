<template>
  <div>
    <Loader/>
    <div class="wrapper">
      <!-- Sidebar  -->
      <SideBarStyle1 :items="verticalMenu" :logo="logo" @toggle="sidebarMini"/>
      <!-- TOP Nav Bar -->
      <NavBarStyle1 title="Dashboard" :homeURL="{ name: 'dashboard1.home' }" @toggle="sidebarMini" :logo="logo">
      </NavBarStyle1>
      <!-- Right Nav Bar 
      <RightSideBar toggleClass="top-50 setting-toggle iq-card">
        <i class="ri-sound-module-fill font-size-18 text-primary" slot="icon"/>
      </RightSideBar>
      -->

      <!-- TOP Nav Bar END -->
      <div id="content-page" class="content-page">
        <div class="container">
          <!-- vyskakovaci okno s notifikaci -->
          <b-modal id="modal-call" centered no-backdrop>
            <h4>{{ $t("modal_call.receive_call") }}</h4>
            <div v-show="this.calls.length > 0">
              <div v-for="call in calls" :key="call.id">            
                <input type="button" @click="clickButtonCall()" :value="call.from" class="btn btn-primar">             
              </div>
            </div>
            <b-button block class="close-modal" @click="$bvModal.hide('modal-call')">{{ $t("modal_call.close") }}</b-button> 
          </b-modal>
          <!-- konec vyskakovaciho okna s notifikaci --> 
          <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in"
                      :leave-active-class="`animated ${animated.exit}`">
            <router-view/>
          </transition>
        </div>
      </div>
    </div>
    <!--
    <FooterStyle1>
      <template v-slot:left>
        <li class="list-inline-item"><a href="#">Privacy Policy</a></li>
        <li class="list-inline-item"><a href="#">Terms of Use</a></li>
      </template>
      <template v-slot:center>
      <img src="/img/intim-club-logo-web.png" alt=""  class="logo-footer">
      </template>
      <template v-slot:right>
      </template>
    </FooterStyle1>
    -->
  </div>
</template>
<script>
import Loader from '../components/socialvue/loader/Loader'
import SideBarStyle1 from '../components/socialvue/sidebars/SideBarStyle1'
import NavBarStyle1 from '../components/socialvue/navbars/NavBarStyle1'
import SideBarItems from '../FackApi/json/SideBar'
import loader from '../assets/images/logo.png'
import RightSideBar from '../components/socialvue/rightsidebar/RightSideBar'
import FooterStyle1 from '../components/socialvue/footer/FooterStyle1'
import {BootstrapVue} from '../plugins/bootstrap-vue'
import {socialvue} from '../config/pluginInit'
import {mapActions} from 'vuex'
import Vue from 'vue'
import api from "@/api/api"


export default {
  name: 'Layout1',
  components: {
    Loader,
    SideBarStyle1,
    NavBarStyle1,
    RightSideBar,
    FooterStyle1,
  },
  mounted() {
    this.logo = loader
    this.toggleMenu()
    socialvue.index();
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    }
  },
  watch: {
    $route() {
      this.toggleMenu()
    },
  },
  // sidebarTicket
  data() {
    return {
      animated: {enter: 'zoomIn', exit: 'zoomOut'},
      animateClass: [
        {value: {enter: 'zoomIn', exit: 'zoomOut'}, text: 'Zoom'},
        {value: {enter: 'fadeInUp', exit: 'fadeOutDown'}, text: 'Fade'},
        {value: {enter: 'slideInLeft', exit: 'slideOutRight'}, text: 'Slide'},
        {value: {enter: 'rotateInDownLeft', exit: 'rotateOutDownLeft'}, text: 'Roll'}
      ],
      verticalMenu: null,
      logo: loader,
      calls: [],
      refresh: null,
      modalOpenedCallsIds: [],
    }
  },
  created() {
    this.startRefresh();
    this.checkCalls();
  },
  beforeDestroy() {
    this.endRefresh();
  },
  methods: {
    clickButtonCall() {
      this.$router.push({path: `/videocallcli/${this.calls[0].id}/${this.calls[0].from}`});
      this.$bvModal.hide("modal-call");
    },
    toggleMenu() {
      if (this.$store.getters["Setting/authUserState"]) {
        if (this.$store.getters["Setting/clientData"]) {
          this.verticalMenu = SideBarItems.loggedClient
        } else {
          this.verticalMenu = SideBarItems.logged
        }
      } else {
        this.verticalMenu = SideBarItems.guest
      }
    },
    sidebarMini() {
      socialvue.triggerSet()
    },
    logout() {
      localStorage.removeItem('user')
      localStorage.removeItem('access_token')
      this.$router.push({name: 'auth1.sign-in1'})
    },
    langChange(lang) {
      this.langChangeState(lang)
      this.$i18n.locale = lang.value
      document.getElementsByClassName('iq-show')[0].classList.remove('iq-show')
    },
    checkCalls() {
      api
        .post('calls/checkCalls', {
          token: this.token
        })
        .then((response) => {
          this.calls = response.data.calls;
            //zobrazení vyskakovacího okna pri prichozim volani 
            if (this.calls.length > 0) {             
              for (let i = 0; i < this.calls.length; i++) {
                if(this.modalOpenedCallsIds.includes(this.calls[i].id) == false) {
                  this.$bvModal.show("modal-call");
                };
                this.modalOpenedCallsIds[i] = this.calls[i].id;
              };
            //zavreni vyskakovacího okna pri neprijetí příchozího volání
            } else {
              this.$bvModal.hide("modal-call");
              console.log("else");
            }
        });
    },
    startRefresh() {
      this.refresh = setInterval(this.checkCalls, 2000);
    },
    endRefresh() {
      if (this.refresh != null) {
        clearInterval(this.refresh);
        this.refresh = null;
      };
    },
    ...mapActions({
      langChangeState: 'Setting/setLangAction'
    })
  },
  computed: {
    token() {
      return this.$store.getters["Setting/token"];      
    }
  }
}
</script>
<style>
@import url("../assets/css/custom.css");
@import url("../assets/css/PriceSlider.css");

input[type="button"] {
  background-color: #ef476f;
  color: white;
  width: 100%;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 20px;
  z-index: 100000;
}

.modal-header {
  display: none;
}

.modal-footer {
  display: none;
}

.modal-content {
  background-color: var(--iq-dark);
}

.close-modal {
  width: 30%;
  float: right;
}

.logo-footer {
  display: block;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  background-position: center center;
}
</style>
